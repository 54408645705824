import { gql } from 'graphql-request'

export const allProjects = gql`
  {
    projects {
      id
      name
      contact_goal
      start_date
      is_orderable
      end_date
      price
      booked_time_in_seconds
      min_time_in_seconds
    }
  }
`

export const projectQuery = gql`
  query project($id: int) {
    project {
      appointments
      booked_time_in_seconds
      min_time_in_seconds
      client
      contact_goal
      is_orderable
      end_date
      id
      legacy_project_id
      min_time_in_seconds
      name
      pharmacies
      price
      price_multiple_in_cents
      start_date
    }
  }
`

export const activeProjects = gql`
  {
    activeProjects {
      id
      name
      contact_goal
      start_date
      end_date
      is_orderable
      price
      booked_time_in_seconds
      min_time_in_seconds
    }
  }
`

export const getPharmacyProjects = gql`
  query pharmacyById($id: [ID!]) {
    pharmaciesByIds(ids: $id) {
      id
      projects {
        name
        id
      }
    }
  }
`

export const getLinks = gql`
  {
    links {
      id
      name
      link
      password
      username
    }
  }
`
