import { useQuery } from 'react-query'
import { getLinks, useAuth, useGqlClient } from '@aposphaere/core-kit'
import { QueryOptions } from './utils'

type Links = {
  id: string
  name: string
  link: string
  password?: string
  username: string | null
}

export const useGetLinks = (options?: QueryOptions<Links[]>) => {
  const gqlClient = useGqlClient()
  const auth = useAuth()
  return useQuery('links', async () => (await gqlClient.request<{ links: Links[] }>(getLinks))?.links, {
    enabled: !!auth.user,
    staleTime: Infinity,
    ...options,
  })
}